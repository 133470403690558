import styled from "styled-components"

const LogoRoll = styled.div`
  padding: ${({ theme }) => theme.space[4]};
  display: grid;
  grid-gap: ${({ theme }) => theme.space[2]};

  h3,
  h4,
  p {
    margin: 0;
  }

  p {
    margin-bottom: ${({ theme }) => theme.space[4]};
  }
`

const LogoRollLogos = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space[4]};
  justify-items: center;
  align-items: center;

  img {
    width: ${({ theme }) => theme.space[9]};
    height: ${({ theme }) => theme.space[7]};
    object-fit: contain;
    margin: ${({ theme }) => `${theme.space[1]}`};

    @supports (mix-blend-mode: multiply) {
      mix-blend-mode: multiply;
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: auto auto auto;
    /* img {
      width: ${({ theme }) => theme.space[10]};
      height: ${({ theme }) => theme.space[9]};
      object-fit: contain;
    } */
  }

  @media ${({ theme }) => theme.device.laptop} {
    grid-template-columns: auto auto auto auto auto auto;
    /* img {
      width: ${({ theme }) => theme.space[10]};
      height: ${({ theme }) => theme.space[9]};
      object-fit: contain;
    } */
  }
`

export default { LogoRoll, LogoRollLogos }
