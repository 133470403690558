/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"

import Container from "../components/layout/Container"
import Layout from "../components/layout/Layout"
import HeroHeader from "../components/common/HeroHeader"
import SEO from "../components/utility/SEO"

import Plan from "../components/common/Plan"
import Points from "../components/common/Points"
import CTABlock from "../components/common/CTABlock"
import LogoRoll from "../components/common/LogoRoll"

const LandingPage = ({ data: { prismicLandingPage } }) => {
  const { data } = prismicLandingPage
  return (
    <Layout audienceType={data.audience_type}>
      <SEO
        title={data.title.text}
        description={data.meta_description.text}
        canonicalUrl={data.canonical_url.text}
      />
      <HeroHeader
        heading={data.hero_heading.text}
        introduction={data.hero_text.text}
        CTAButtonLabel={data.header_cta_button_label.text}
        CTASubText={data.header_cta_sub_text.text}
        CTALink={data.header_cta_link.url}
      />
      <Container>
        <LogoRoll
          heading={data.logos_heading.text}
          subHeading={data.logos_sub_heading.text}
          logos={data.logos.map(({ name, image }) => ({
            name: name.text,
            image: image.url,
          }))}
        />
      </Container>
      <Container>
        <Points
          heading={data.problem_heading.text}
          points={data.pain_points.map((pp) => ({
            heading: pp.heading.text,
            text: pp.text.text,
            image: pp.image.url,
            imageAlt: pp.image.alt,
          }))}
        />
      </Container>
      <CTABlock
        heading={data.guide_heading.text}
        buttonLabel={data.guide_cta_button_label.text}
        subText={data.guide_cta_sub_text.text}
        link={data.guide_cta_link.url}
      />
      <Container>
        <Plan
          heading={data.plan_heading.text}
          steps={data.plan_steps.map((s) => ({
            heading: s.heading.text,
            description: s.description.html,
            image: s.image.url,
            imageAlt: s.image.alt,
            ctaLabel: s.step_cta_label.text,
            ctaLink: s.step_cta_link.url,
            infoLink: s.info_cta_link.url,
            infoLabel: s.info_cta_label.text,
          }))}
        />
      </Container>
      {data.cta_heading.text && (
        <CTABlock
          heading={data.cta_heading.text}
          text={data.cta_text.text}
          buttonLabel={data.cta_button_label.text}
          subText={data.cta_sub_text.text}
          link={data.cta_link.url}
        />
      )}
    </Layout>
  )
}

export default LandingPage

export const pageQuery = graphql`
  query LandingPageBySlug($uid: String!) {
    prismicLandingPage(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        audience_type
        meta_description {
          text
        }
        canonical_url {
          text
        }
        hero_heading {
          text
        }
        hero_text {
          text
        }
        header_cta_button_label {
          text
        }
        header_cta_sub_text {
          text
        }
        header_cta_link {
          url
        }
        logos_heading {
          text
        }
        logos_sub_heading {
          text
        }
        logos {
          name {
            text
          }
          image {
            url
          }
        }
        problem_heading {
          text
        }
        pain_points {
          heading {
            text
          }
          text {
            text
          }
          image {
            url
            alt
          }
        }
        guide_heading {
          text
        }
        guide_cta_button_label {
          text
        }
        guide_cta_sub_text {
          text
        }
        guide_cta_link {
          url
        }
        plan_heading {
          text
        }
        plan_steps {
          heading {
            text
          }
          description {
            html
          }
          image {
            url
            alt
          }
          step_cta_label {
            text
          }
          step_cta_link {
            url
          }
          info_cta_label {
            text
          }
          info_cta_link {
            url
          }
        }
        cta_heading {
          text
        }
        cta_text {
          text
        }
        cta_button_label {
          text
        }
        cta_sub_text {
          text
        }
        cta_link {
          url
        }
      }
    }
  }
`
