/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import PropTypes from "prop-types"
import Styles from "./Styles"

const Points = ({ heading, subHeading, points, ...props }) => (
  <Styles.Points>
    {heading && <h4 className="text-center">{heading}</h4>}
    {subHeading && <p className="text-center">{subHeading}</p>}
    <Styles.PointsList {...props}>
      {points.map(({ heading, text, image, imageAlt }) => (
        <Styles.PointsItem>
          <img src={image} alt={imageAlt} />
          <div>
            <h5>{heading}</h5>
            <p>{text}</p>
          </div>
        </Styles.PointsItem>
      ))}
    </Styles.PointsList>
  </Styles.Points>
)

Points.defaultProps = {
  heading: undefined,
}

Points.propTypes = {
  heading: PropTypes.string,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
    })
  ).isRequired,
}

export default Points
