import styled from "styled-components"

const Points = styled.div`
  padding: ${({ theme }) => `${theme.space[4]} 0`};
  display: grid;
  grid-gap: ${({ theme }) => theme.space[2]};
`

const PointsList = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space[4]};
  justify-items: center;
  align-items: center;

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: auto auto auto;
    grid-gap: ${({ theme }) => theme.space[6]};
  }
`

const PointsItem = styled.div`
  padding: ${({ theme }) => theme.space[4]};
  display: grid;
  grid-gap: ${({ theme }) => theme.space[4]};
  justify-items: center;
  align-items: center;
  text-align: center;

  h4,
  h5,
  p {
    margin: 0;
  }

  img {
    width: ${({ theme }) => theme.space[9]};
    height: ${({ theme }) => theme.space[9]};
    object-fit: contain;
  }
`

export default { Points, PointsList, PointsItem }
