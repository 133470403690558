/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import PropTypes from "prop-types"
import Styles from "./Styles"
import Button, { ButtonGroup } from "../Button"

const Plan = ({ heading, subHeading, steps, ...props }) => (
  <Styles.Plan>
    {heading && <h4 className="text-center">{heading}</h4>}
    {subHeading && <p className="text-center">{subHeading}</p>}
    <Styles.PlanSteps {...props}>
      {steps.map(
        ({
          heading,
          description,
          image,
          imageAlt,
          ctaLabel,
          ctaLink,
          infoLink,
          infoLabel,
        }) => (
          <Styles.PlanStep>
            <img src={image} alt={imageAlt} />
            <div>
              <h3>{heading}</h3>
              <div dangerouslySetInnerHTML={{ __html: description }} />
              <ButtonGroup>
                {ctaLink && (
                  <Button mode="primary" label={ctaLabel} linkTo={ctaLink} />
                )}
                {infoLink && (
                  <Button mode="default" label={infoLabel} linkTo={infoLink} />
                )}
              </ButtonGroup>
            </div>
          </Styles.PlanStep>
        )
      )}
    </Styles.PlanSteps>
  </Styles.Plan>
)

Plan.defaultProps = {
  heading: undefined,
}

Plan.propTypes = {
  heading: PropTypes.string,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
    })
  ).isRequired,
}

export default Plan
