/* eslint-disable react/jsx-props-no-spreading */
import React from "react"
import PropTypes from "prop-types"
import Styles from "./Styles"

const LogoRoll = ({ heading, subHeading, logos, compact, ...props }) => (
  <Styles.LogoRoll>
    {heading && <h4 className="text-center">{heading}</h4>}
    {subHeading && <p className="text-center">{subHeading}</p>}
    <Styles.LogoRollLogos {...props}>
      {logos.map(({ name, image, link }) =>
        link ? (
          <a key={name} href={link}>
            <img src={image} alt={name} />
          </a>
        ) : (
          <img key={name} src={image} alt={name} />
        )
      )}
    </Styles.LogoRollLogos>
  </Styles.LogoRoll>
)

LogoRoll.defaultProps = {
  heading: undefined,
  compact: false,
}

LogoRoll.propTypes = {
  heading: PropTypes.string,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      image: PropTypes.string,
      link: PropTypes.string,
    })
  ).isRequired,
  compact: PropTypes.bool,
}

export default LogoRoll
