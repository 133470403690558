import React from "react"
import PropTypes from "prop-types"
import CTAButton from "../../common/CTAButton"
import Container from "../../layout/Container"
import Styles from "./Styles"

const HeroHeader = ({
  heading,
  introduction,
  CTAButtonLabel,
  CTASubText,
  CTALink,
}) => {
  return (
    <Styles.HeroHeader>
      <Container>
        <h1>{heading}</h1>
        <p className="lead">{introduction}</p>
        <CTAButton label={CTAButtonLabel} text={CTASubText} link={CTALink} />
      </Container>
    </Styles.HeroHeader>
  )
}

HeroHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  introduction: PropTypes.string.isRequired,
  CTAButtonLabel: PropTypes.string,
  CTASubText: PropTypes.string,
  CTALink: PropTypes.string,
}

HeroHeader.defaultProps = {
  CTAButtonLabel: undefined,
  CTASubText: undefined,
  CTALik: undefined,
}

export default HeroHeader
