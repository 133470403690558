import styled from "styled-components"
import { Styles as CTAButtonStyles } from "../../common/CTAButton"

const HeroHeader = styled.div`
  padding: ${({ theme }) => `${theme.space[4]}`};
  background-color: ${({ theme }) => theme.color["gray-200"]};
  text-align: center;

  @media ${({ theme }) => theme.device.mobileL} {
    background-color: ${({ theme }) => theme.color["gray-100"]};
  }

  @media ${({ theme }) => theme.device.tablet} {
    padding: 5rem 2rem 0;
  }

  img {
    width: 100%;
    max-width: 1000px;
    display: block;
    margin: 3rem auto;
  }

  ${CTAButtonStyles.CTAButton} {
    margin-top: 4rem;
  }
`

export default {
  HeroHeader,
}
